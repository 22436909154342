.container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    background-color: grey;
    min-height:100vh;
    padding-top:50px
}
  
.content {
    flex:1;
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;
    max-width: 800px;
    background-color:white;
    padding:20px;
    margin: 0 auto;
    box-sizing:border-box;
}

footer {
    background-color:black;
    color:white;
    text-align:center;
    padding:20px;
}

.footer-link:link{
    color:lightgray;
}

.footer-link:visited {
    color:lightgray;
}

.footer-link:hover {
    color:darkcyan;
}

.footer-link:active {
    color:darkcyan;
}

p {
    white-space:pre-line;
}

@media (max-width: 581px) {
    .container {
        padding-top: 100px;
    }
}